<template>
	<div
		v-if="is_error"
		fill-height
		class="bg-error"
		style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center"
	>

		<div
			class="text-white text-center text-subtitle-1 mt-10"
			style="font-size: 16px;"
		>
			<div class="text-center">
				<v-icon
					large
					style="color: red; font-size: 3em"
				>mdi-alert-outline</v-icon>
			</div>
			<p class="pa-10 mt-10">{{  $language.common.message_error }}</p>
			<button class=" mt-30" style="width: 100%; border: 1px solid #ddd; padding: 10px !important; height: auto !important; border-radius: 10px; background-color: #eee; color: black" @click="$router.back()">{{  $language.common.ok }}</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DuplicatePage'

		,data: function(){
			return {
				program: {
					title: 'Not Found Page'
					,not_header: true
					,not_footer: true
				}
				, is_error: false
			}
		}
		, async created() {
			console.log('in duplication')

			let params = this.$route.params
			let name = params.name
			delete params.name
			let query = this.$route.query

			console.log(name, params)

			if(name){
				setTimeout( () => {
					this.$router.push({ name: name, params: params, query: query}).catch( (e) =>{
						console.log(e)
						this.is_error = true
					})
				}, 100)
			}else{
				setTimeout( () => {
					this.$router.back()
				}, 100)
			}

			this.$emit('onLoad', this.program)
		}
	}
</script>

<style>
.bg-error {background-color: #15324E !important; color: white; text-align: center;}
.fill-height {height: 100%; width: 100%;}
</style>